<template>
  <div class="box" :style="activeStyle" @click="itemClick(questionItem.infoId)">
    <div class="qa">
        <div class="q">
            <div class="w">问:</div>
            <div class="t">{{questionItem.title}}</div>
        </div>
        <div class="a">
            <div class="d">答:</div>
            <div class="h">{{questionItem.infoContent}}</div>
        </div>
    </div>
    <div class="doc">
      <div class="imgsize">
        <img :src="questionItem.avatar" alt="">
      </div>
        <span class="docname">{{questionItem.doctorName}}</span>
        <span class="docloc">{{questionItem.hospital}}</span>
        <div class="docty">
            <span class="type">{{questionItem.doctorTitle}}</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QusetionAnswer',
  props: {
    questionItem: {
      type: Object,
      default () {
        return {}
      }
    },
    questionIndex: {
      type: Number
    }
  },
  mounted () {
    // console.log(this.questionItem)
  },
  computed: {
    activeStyle () {
    //   return this.questionIndex ? { left: ((this.questionIndex === 1 || this.questionIndex === 3) ? 48 + 'px' : ((this.questionIndex === 2) ? 48 + 'px' : 0)) } : {}
    // return this.questionIndex ? { left: (this.questionIndex === 1 || this.questionIndex === 1 ? 48 + 'px' : ((this.questionIndex > 1) ? (48) + 'px' : 0)), top: (this.questionIndex > 1 ? 48 + 'px' : 0) } : {}
      return this.questionIndex ? { left: (this.questionIndex === 1 || this.questionIndex === 3 ? 24 + 'px' : 0), top: (this.questionIndex > 1 ? 48 + 'px' : 0) } : {}
    }
  },
  methods: {
    itemClick (e) {
      this.$router.push({ path: '/mobQuestionCenterDel', query: { qaId: e } })
    }
  }
}
</script>

<style scoped>
.box{
    position: relative;
    width: 550px;
    height: 210px;
    background: #FAFAFA;
    border-radius: 4px;
}
.qa{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: absolute;
    width: 470px;
    height: 102px;
    left: 40px;
    top: 16px;
}
.q{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    position: static;
    /* width: 197px; */
    height: 24px;
    left: 0px;
    top: 0px;
}
.w{
    position: static;
    width: 32px;
    height: 24px;
    left: 0px;
    top: 0px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #262626;
}
.t{
    position: static;
    width: 430px;
    height: 24px;
    left: 32px;
    top: 0px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #262626;
    overflow: hidden;  /*超出部分隐藏*/
    white-space: nowrap;  /*禁止换行*/
    text-overflow: ellipsis; /*省略号*/
}
.a{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    position: static;
    width: 472px;
    height: 72px;
    left: 0px;
    top: 32px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px;
    color: #8C8C8C;
}
.d{
    position: static;
    width: 32px;
    height: 24px;
    left: 0px;
    top: 0px;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
    font-weight: bold;
}
.h{
    position: static;
    width: 440px;
    height: 72px;
    left: 32px;
    top: 0px;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    word-wrap:break-word;
}
.doc{
    position: absolute;
    width: 220px;
    height: 54px;
    left: 40px;
    top: 140px;
}
.docty{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    position: absolute;
    /* width: 63px; */
    height: 20px;
    left: 132px;
    top: 6px;
    color:#4263EB;
    border: 1px solid #4263EB;
    box-sizing: border-box;
    border-radius: 2px;
}
.imgsize{
  width: 54px;
  height: 54px;
  border-radius: 27px;
}
.imgsize img{
  width: 100%;
  height: 100%;
  border-radius: 27px;
}
.docty .type{
    position: static;
    /* width: 55px; */
    height: 16px;
    left: 4px;
    top: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
}
.doc img{
    position: absolute;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
    border: 1px solid #D9E3FE;
    box-sizing: border-box;
}
.docname{
    position: absolute;
    width: 42px;
    height: 22px;
    left: 76px;
    top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #262626;
    /* font-family: 'OPPOSans B'; */
    font-weight: bold;
}
.docloc{
    position: relative;
    /* width: 144px; */
    height: 20px;
    left: 76px;
    top: -22px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
}
</style>
